import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import { emphasize } from "@mui/material";

export default function CharacterCard({
  character,
  onCharacterDelete,
  color,
}: {
  character: string;
  onCharacterDelete: () => void;
  color: string;
}) {
  return (
    <Card
      sx={{
        maxWidth: 300,
        backgroundColor: color,
        width: "100%",
      }}
    >
      <CardActions>
        <IconButton
          aria-label="share"
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "right",
          }}
          onClick={() => onCharacterDelete()}
        >
          <DeleteIcon htmlColor={emphasize(color, 0.85)} />
        </IconButton>
      </CardActions>
      <CardContent>
        <Typography
          variant="h5"
          component="div"
          sx={{
            textAlign: "center",
            color: emphasize(color, 1),
          }}
        >
          {character === " " ? "(space character)" : character}
        </Typography>
      </CardContent>
    </Card>
  );
}
