import React from "react";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Alert from "@mui/material/Alert";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import SearchIcon from "@mui/icons-material/Search";
import Typography from "@mui/material/Typography";

import { Form } from "react-router-dom";

const InputPage = () => {
  const [errorMessage, setErrorMessage] = React.useState<string>("");
  return (
    <div>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          height: "100vh",
        }}
      >
        <Box
          sx={{
            form: {
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
              padding: 2,
            },
            width: {
              xs: "100%",
              sm: "80%",
              md: "60%",
            },
            textAlign: "center",
          }}
          id="enter-text"
        >
          <Typography variant="h5" sx={{ my: 2 }}>
            Duplicate Character Remover
          </Typography>
          <Collapse in={errorMessage !== ""}>
            <Alert
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={() => {
                    setErrorMessage("");
                  }}
                >
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }
              sx={{ m: 2 }}
              severity="error"
            >
              {errorMessage}
            </Alert>
          </Collapse>
          <Form
            action="display"
            method="get"
            onSubmit={(e) => {
              const val = (e.target as any).q.value;
              if (val.trim() === "") {
                e.preventDefault();
                setErrorMessage("Please enter some text that's not whitespace");
                return;
              }
            }}
          >
            <TextField
              id="text-input"
              label="Enter text to work with"
              variant="outlined"
              name="q"
              fullWidth
            />
            <Button
              variant="contained"
              sx={{ my: 2 }}
              type="submit"
              startIcon={<SearchIcon />}
            >
              Submit
            </Button>
          </Form>
        </Box>
      </Box>
    </div>
  );
};

export default InputPage;
