import * as React from "react";

import Fab from "@mui/material/Fab";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import Brightness4Icon from "@mui/icons-material/Brightness4";
import Brightness7Icon from "@mui/icons-material/Brightness7";
import ColorModeContext from "../contexts/DarkMode";
import { useTheme } from "@mui/material/styles";

const DarkModeToggle = () => {
  const theme = useTheme();
  const colorMode = React.useContext(ColorModeContext);
  return (
    <Fab
      color="primary"
      aria-label="dark mode"
      sx={{ position: "fixed", bottom: 32, right: 32 }}
      onClick={colorMode.toggleColorMode}
      component="span"
    >
      <Tooltip
        title={
          theme.palette.mode === "dark"
            ? "Toggle light mode"
            : "Toggle dark mode"
        }
      >
        <span>
          <IconButton color="inherit">
            {theme.palette.mode === "dark" ? (
              <Brightness7Icon />
            ) : (
              <Brightness4Icon />
            )}
          </IconButton>
        </span>
      </Tooltip>
    </Fab>
  );
};

export { DarkModeToggle };
