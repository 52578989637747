import { PaletteMode } from "@mui/material";

// returns random bright color in hsl format
export function getRandomColor(mode: PaletteMode = "light") {
  const hue = Math.floor(Math.random() * 360);
  if (mode === "light") {
    return `hsl(${hue}, 100%, 65%)`;
  }
  return `hsl(${hue}, 100%, 35%)`;
}

// for a give character, returns same color every time
// also make sure that no two characters have same color
const colorMap = new Map();
export function getColorForCharacter(char: string, mode: PaletteMode = "light") {
  const key = `${char}-${mode}`;
  if (colorMap.has(key)) {
    return colorMap.get(key);
  }
  let color = getRandomColor(mode);
  // make sure that no two characters have same color
  while (Array.from(colorMap.values()).includes(color)) {
    color = getRandomColor();
  }
  colorMap.set(key, color);
  return color;
}

// find if there are duplicate characters in a string
// consider case insensitive
export function hasDuplicateCharacters(str: string[]) {
  const map = new Map();
  for (let i = 0; i < str.length; i++) {
    const char = str[i].toLowerCase();
    if (map.has(char)) {
      return true;
    }
    map.set(char, true);
  }
  return false;
}

// join characters
export function joinCharacters(obj: { character: string; id: string }[]) {
  return obj.map((item) => item.character).join("");
}

const COLOR_MODE_LOCAL_STORAGE_KEY = "color-mode";

const initialColorMode = (): PaletteMode => {
  const persistedColorPreference = window.localStorage.getItem(
    COLOR_MODE_LOCAL_STORAGE_KEY
  );
  const hasPersistedPreference = typeof persistedColorPreference === "string";

  // If the user has explicitly chosen light or dark,
  // use it. Otherwise, this value will be null.
  if (hasPersistedPreference) {
    return persistedColorPreference as PaletteMode;
  }

  // If they haven't been explicit, let's check the media
  // query
  if (typeof window !== "undefined") {
    const mql = window.matchMedia("(prefers-color-scheme: dark)");
    const hasMediaQueryPreference = typeof mql.matches === "boolean";

    if (hasMediaQueryPreference) {
      return mql.matches ? "dark" : "dark";
    }
  }

  // If they are using a browser/OS that doesn't support
  // color themes, let's default to 'light'.
  return "light";
};

const saveColorMode = (mode: string) => {
  window.localStorage.setItem(COLOR_MODE_LOCAL_STORAGE_KEY, mode);
};

export { initialColorMode, saveColorMode };
