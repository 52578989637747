import React from "react";
import { useLoaderData } from "react-router-dom";
import Box from "@mui/material/Box";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import Typography from "@mui/material/Typography";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import CharacterCard from "../components/CharacterCard";
import Toolbar from "@mui/material/Toolbar";
import {
  getColorForCharacter,
  hasDuplicateCharacters,
  joinCharacters,
} from "../utils";
import { useTransition, animated } from "@react-spring/web";
import uuid4 from "uuid4";
import { useTheme } from "@mui/material/styles";
import ResponsiveDrawer, { drawerWidth } from "../components/ResponsiveDrawer";
import Divider from "@mui/material/Divider";
import Paper from "@mui/material/Paper";
import Tooltip from "@mui/material/Tooltip";
import Snackbar from "@mui/material/Snackbar";
import CharacterFrequency from "../components/CharacterFrequency";

interface DisplayProps {
  query: string;
}

const CopyToClipboardView = ({
  textElement,
  clipboardMessage,
}: {
  textElement: JSX.Element;
  clipboardMessage: string;
}) => {
  const [toolTipMessage, setToolTipMessage] = React.useState<string>(
    "Click to copy to clipboard!"
  );

  return (
    <Tooltip title={toolTipMessage}>
      <Paper
        onClick={() => {
          navigator.clipboard.writeText(clipboardMessage);
          setToolTipMessage("Copied to clipboard!");
          setTimeout(() => {
            setToolTipMessage("Click to copy to clipboard!");
          }, 1000);
        }}
      >
        <Typography>{textElement}</Typography>
      </Paper>
    </Tooltip>
  );
};

export default function Display() {
  const { query } = useLoaderData() as DisplayProps;
  const theme = useTheme();

  const [charDisplayObjects, setCharDisplayObjects] = React.useState<
    { character: string; id: string }[]
  >([]);
  const [successMessage, setSuccessMessage] = React.useState<string>("");
  const [helpMessage, setHelpMessage] = React.useState<string>("");

  React.useEffect(() => {
    setCharDisplayObjects(
      [...query].map((character) => ({ character, id: uuid4() }))
    );
  }, [query]);

  React.useEffect(() => {
    const characters = charDisplayObjects.map(
      (charDisplayObject) => charDisplayObject.character
    );
    if (hasDuplicateCharacters(characters)) {
      setSuccessMessage("");
    } else {
      setSuccessMessage(
        "Congratulations! You have removed all duplicate characters!"
      );
    }
  }, [charDisplayObjects]);

  const transitions = useTransition(charDisplayObjects, {
    keys: (item) => item.id,
    initial: { opacity: 0, width: 0 },
    from: { opacity: 0, width: 300 },
    leave: { opacity: 0, width: 0 },
    enter: { opacity: 1, width: 300 },
    exitBeforeEnter: true,
    config: { duration: 500 },
  });

  const handleHelpMessageClose = () => {
    setHelpMessage("");
  };

  return (
    <Box sx={{ display: { sm: "flex", xs: "block" } }}>
      <ResponsiveDrawer
        title="Duplicate Character Remover"
        drawer={
          <>
            <Toolbar />
            <Divider />
            <Box
              sx={{
                textAlign: "center",
                "& .MuiPaper-root": {
                  m: 2,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  borderRadius: 4,
                  cursor: "pointer",
                },
                "& .MuiTypography-root": {
                  my: 2,
                },
              }}
            >
              <CopyToClipboardView
                textElement={
                  <>
                    <b>Original String:</b> {query}
                  </>
                }
                clipboardMessage={query}
              />
              {/* show the modified string from the characters */}
              <CopyToClipboardView
                textElement={
                  <>
                    <b>Resultant String:</b>{" "}
                    {joinCharacters(charDisplayObjects)}
                  </>
                }
                clipboardMessage={joinCharacters(charDisplayObjects)}
              />
              <Divider />
              <CharacterFrequency query={query} />
            </Box>
          </>
        }
      />
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          width: { sm: `calc(100% - ${drawerWidth}px)` },
        }}
      >
        <Toolbar sx={{ display: { sm: "flex", xs: "none" } }} />

        <Collapse in={successMessage !== ""}>
          <Alert
            severity="success"
            sx={{ width: "100%", textAlign: "left", my: 2 }}
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  setSuccessMessage("");
                }}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }
          >
            <AlertTitle>{successMessage}</AlertTitle>
            <div>
              Original String: <b>{query}</b>
            </div>
            <div>
              Resultant String: <b>{joinCharacters(charDisplayObjects)}</b>
            </div>
          </Alert>
        </Collapse>
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "center",
            alignItems: "center",
            alignContent: "center",
            gap: "1rem",
          }}
        >
          {transitions((style, item, t) => {
            return (
              <animated.div style={{ ...style }} key={item.id}>
                <CharacterCard
                  character={item.character}
                  onCharacterDelete={() => {
                    const newCharDisplayObjects = charDisplayObjects.filter(
                      (c) =>
                        c.character.toLowerCase() !==
                          item.character.toLowerCase() || c.id === item.id
                    );
                    setCharDisplayObjects(newCharDisplayObjects);
                    // check if newCharDisplayObjects and charDisplayObjects have same items
                    if (
                      newCharDisplayObjects.length ===
                      charDisplayObjects.length
                    ) {
                      setHelpMessage(
                        "There's only one instance of this character. Try removing another character."
                      );
                    }
                  }}
                  color={getColorForCharacter(
                    item.character.toLowerCase(),
                    theme.palette.mode
                  )}
                />
              </animated.div>
            );
          })}
        </Box>
      </Box>
      <Snackbar
        open={helpMessage !== ""}
        autoHideDuration={6000}
        onClose={handleHelpMessageClose}
        action={
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={handleHelpMessageClose}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        }
      >
        <Alert
          onClose={handleHelpMessageClose}
          severity="info"
          sx={{ width: "100%" }}
        >
          {helpMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
}

export async function loader({ request }: { request: Request }) {
  const url = new URL(request.url);
  const q = url.searchParams.get("q");
  return { query: q };
}
