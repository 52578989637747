import * as React from "react";
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { Outlet } from "react-router-dom";
import { initialColorMode, saveColorMode } from "../utils";
import { PaletteMode } from "@mui/material";
import ColorModeContext from "../contexts/DarkMode";
import { DarkModeToggle } from "../components/DarkModeToggle";

export default function Root() {
  const [mode, setMode] = React.useState<PaletteMode | null>(null);
  React.useEffect(() => {
    const initialColor = initialColorMode();
    setMode(initialColor);
  }, []);

  const colorMode = React.useMemo(
    () => ({
      toggleColorMode: () => {
        setMode((prevMode) => {
          const newMode = prevMode === "light" ? "dark" : "light";
          saveColorMode(newMode);
          return newMode;
        });
      },
    }),
    []
  );

  const theme = React.useMemo(
    () =>
      createTheme({
        palette: {
          mode: mode || "light",
        },
      }),
    [mode]
  );

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Outlet />
        <DarkModeToggle />
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
}
