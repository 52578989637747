import React from "react";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";

export default function CharacterFrequency({ query }: { query: string }) {
  const [frequencies, setFrequencies] = React.useState<[string, number][]>([]); // [character, frequency]

  React.useEffect(() => {
    const frequencies = [...query].reduce((acc, curr) => {
      if (acc[curr]) {
        acc[curr] += 1;
      } else {
        acc[curr] = 1;
      }
      return acc;
    }, {} as { [key: string]: number });
    // sort by frequency
    const sortedFrequencies = Object.entries(frequencies).sort(
      ([, aFreq], [, bFreq]) => bFreq - aFreq
    );
    setFrequencies(sortedFrequencies);
  }, [query]);

  return (
    <>
      <Typography variant="h6">Character Frequency</Typography>
      {frequencies.map(([character, frequency]) => (
        <Paper
          key={character}
          sx={{
            "& .MuiTypography-root": {
              my: 1,
            },
          }}
        >
          <Typography>
            {character.trim() || "(space character)"} - {frequency}
          </Typography>
        </Paper>
      ))}
    </>
  );
}
